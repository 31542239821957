var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.authUserPermission['my-notifications-view'])?_c('div',[_c('base-header',{staticClass:"pb-6"},[_c('div',{staticClass:"row align-items-center py-4"},[_c('div',{staticClass:"col-lg-6 col-7"},[_c('h6',{staticClass:"h2 text-white d-inline-block mb-0"})])])]),_c('div',{staticClass:"container-fluid mt--6"},[_c('div',{staticClass:"card mb-4"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h3',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.tt('my_notifications')))])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.table.search),expression:"table.search"}],staticClass:"form-control form-control-sm text-center border-radius-20",attrs:{"type":"text","placeholder":_vm.tt('search')},domProps:{"value":(_vm.table.search)},on:{"keyup":_vm.filter,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.table, "search", $event.target.value)}}})])])])]),(!_vm.onLoad)?_c('el-table',{staticClass:"table-responsive table-flush",attrs:{"height":"350px","header-row-class-name":"thead-light","data":_vm.table.data}},[_c('el-table-column',{attrs:{"label":_vm.tt('sender'),"prop":_vm.tt('sender'),"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.user_data.name)+" ")]}}],null,false,3915999848)}),_c('el-table-column',{attrs:{"label":_vm.tt('message'),"prop":_vm.tt('message'),"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.notification_data.message)+" ")]}}],null,false,1437611160)}),_c('el-table-column',{attrs:{"label":_vm.tt('date'),"prop":_vm.tt('date'),"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.created_at)+" ")]}}],null,false,180520629)}),_c('el-table-column',{attrs:{"prop":"action","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-dropdown',{staticClass:"dropdown",attrs:{"trigger":"click","hide-on-click":true},on:{"command":_vm.handleTableAction}},[_c('span',{staticClass:"btn btn-sm btn-icon-only text-light"},[_c('i',{staticClass:"fas fa-ellipsis-v mt-2"})]),_c('el-dropdown-menu',{staticClass:"dropdown-menu dropdown-menu-arrow show",attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":{action:'view', data:row.notification_data.url}}},[_vm._v("View")]),_c('el-dropdown-item',{attrs:{"command":{action:'remove', data:row.id}}},[_vm._v("Delete")])],1)],1)]}}],null,false,1538707731)})],1):_c('page-loading'),_c('div',{staticClass:"card-footer pb-0 "},[_c('span',{staticClass:"float-left"},[_c('base-pagination',{attrs:{"page-count":_vm.table.page.last_page,"size":"sm"},nativeOn:{"click":function($event){return _vm.changePage(_vm.table.page.current_page)}},model:{value:(_vm.table.page.current_page),callback:function ($$v) {_vm.$set(_vm.table.page, "current_page", $$v)},expression:"table.page.current_page"}})],1),_c('span',{staticClass:"float-right"},[_vm._v(" "+_vm._s(_vm.tt('page_info', {size: (_vm.table.page.current_page - 1) * _vm.table.page.per_page + _vm.table.data.length, total: _vm.table.total}))+" ")])])],1)])],1):_c('noaccess')],1)}
var staticRenderFns = []

export { render, staticRenderFns }