<template>
    <div>
        <div v-if="authUserPermission['my-notifications-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header">
                        <div class="row">
                            <div class="col">
                                <h3 class="mb-0">{{ tt('my_notifications') }}</h3>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <input type="text" class="form-control form-control-sm text-center border-radius-20" v-model="table.search" :placeholder="tt('search')" v-on:keyup="filter"/>                        
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <span v-for="v in table.data"> -->
                        <!-- <p>{{ JSON.parse(v.data).sender }}</p>
                    </span> -->
                        <el-table height="350px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data" v-if="!onLoad">
                            <el-table-column :label="tt('sender')" :prop="tt('sender')" sortable>
                                <template v-slot="{row}">
                                    {{ row.user_data.name }}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('message')" :prop="tt('message')" sortable>
                                <template v-slot="{row}">
                                    {{ row.notification_data.message }}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('date')" :prop="tt('date')" sortable>
                                <template v-slot="{row}">
                                    {{row.created_at}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="action" width="100">
                                <template v-slot="{row}">
                                    <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true">
                                        <span class="btn btn-sm btn-icon-only text-light">
                                            <i class="fas fa-ellipsis-v mt-2"></i>
                                        </span>
                                        <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                            <el-dropdown-item :command="{action:'view', data:row.notification_data.url}">View</el-dropdown-item>
                                            <el-dropdown-item :command="{action:'remove', data:row.id}">Delete</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                        <page-loading v-else/>
                    <div class="card-footer pb-0 ">   
                        <span class="float-left">
                            <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                        </span>                             
                        <span class="float-right">
                            {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total}) }}
                        </span>
                    </div>
                </div>
            </div>
        <!-- <modal :show.sync="form.show">
            <template slot="header">
                <h5 class="modal-title">{{form.title}}</h5>
            </template>
            <div>
                <label class="form-control-label">{{ tt('holding') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('holding')" rules="required">
                    <el-select class="select-danger" v-model="department.holding" placeholder="Choose Holding">
                        <el-option class="select-danger" value="01" label="Abbrevation" key="Abbrevation"></el-option>
                    </el-select>
                </base-input>

                <label class="form-control-label">{{ tt('code') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('code')" :placeholder="tt('code')" v-model="department.code" rules="required"></base-input>

                <label class="form-control-label">{{ tt('description') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('description')" :placeholder="tt('description')" v-model="department.description" rules="required"></base-input>
            </div>
            <template slot="footer">
                <base-button type="secondary" @click="form.show = false">{{ tt('reset') }}</base-button>
                <base-button type="primary">
                    <span v-if="form.add">{{ tt('add') }}</span>
                    <span v-else>{{ tt('edit') }}</span>
                </base-button>
            </template>
        </modal> -->
        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import myNotifications from '@/services/setting/myNotifications.service';

    export default {        
        data() {
            return { 
                onLoad: true,                 
                form: {
                    add: true,
                    title: "Create Department",
                    show: false
                }, 
                table: {
                    search: '',                    
                    total: 0,
                    data: [],
                    page: {
                        current_page: 1,
                        per_page: 100,
                    },
                },        
                myNotifications: {},
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get();
        },
        methods: {
            get() { 
                let context = this;               
                Api(context, myNotifications.get(this.table.page.current_page, {search: this.table.search})).onSuccess(function(response) {    
                    context.table.total = response.data.data.data.total;
                    context.table.data = response.data.data.reArray;  
                    context.table.page  = response.data.data.data; 
                    context.onLoad = false; 
                    // console.log(response.data.data.reArray);
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.table.data = [];
                        context.table.total = 0;
                        context.onLoad = false;
                    }
                })
                .call()
            },
            filter() {
                this.onLoad = true;
                this.table.page.current_page = 1;
                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 10);
            },
            handleTableAction(command) {
                switch (command.action) {
                    case 'view':
                            this.view(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            remove(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        Api(context, myNotifications.delete(id)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success'
                            });
                            context.get();
                        }).call();
                    }
                })
            },
            changePage(page) {
                let context = this;
                context.onLoad = true;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            },
            view(data){
                // alert(JSON.parse(data).url);
                location.href = data;
            }
        }   
    };
</script>
<style></style>
